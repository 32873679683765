html, body {
  height: 100%;
  overflow: auto;
}

body {
    margin: 0;
    color: white;
    text-align: center;
    background-color: #282c34;
}